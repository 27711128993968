import { defineStore } from 'pinia';

const useAirportsStore = defineStore('airportsStore', {
    state: () => ({
        airports: [],
        airport: null,
    }),

    actions: {
        setAirports(airports) {
            this.airports = airports;
        },
    },
});

export { useAirportsStore };
