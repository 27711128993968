<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import dayjs from 'dayjs'
import { usePageStore } from '@voix/store/pageStore'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

interface Props {
  debug: boolean
  acpLang: string
  modelValue: {
    mode: 'resort-only' | 'resort-flight'
    from: string
    destination: any
    dateRange: {
      start: string | Date
      end: string | Date
    }
    rooms: [
      {
        id: string
        adults: number
        children: number
        childrenAges: number[]
      },
    ]
  }
  error: {
    title: string
    message: string
  }
  mbrAgentData: {
    iata: string
    name: string
    email: string
  }
  preferredHotelCode: string
  disabledDates: string[]
  rate: string
  promo: string
  coupon: string
  group: string
  currency: string
  mn: string
  showAgentFields: boolean
  discounts: {
    coupon: string | null
    promo: string | null
    rate: string | null
  }
}

const props = withDefaults(defineProps<Props>(), {
  debug: false,
  acpLang: '',
  modelValue: () => ({
    mode: 'resort-only',
    from: '',
    destination: null,
    dateRange: {
      start: '',
      end: '',
    },
    rooms: [
      {
        id: 'room-1',
        adults: 2,
        children: 0,
        childrenAges: [],
      },
    ],
  }),
  error: () => ({
    title: '',
    message: '',
  }),
  mbrAgentData: () => ({
    iata: '',
    name: '',
    email: '',
  }),
  preferredHotelCode: '',
  disabledDates: () => [],
  rate: '',
  promo: '',
  group: '',
  currency: 'USD',
  mn: '',
  showAgentFields: false,
  discounts: () => ({
    coupon: null,
    promo: null,
    rate: null,
  }),
})

const emit = defineEmits(['setError'])

// Accent color that comes in from the inline booking slice
const accentColor: { value: string } = inject('accentColor') as { value: string }

const showLoading = ref(false)
const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})

const reservhotelform: Ref<HTMLFormElement | null> = ref(null)

const departureDate = computed(() => {
  return dayjs(props.modelValue.dateRange.start).format('DD-MMM-YY')
})

const returnDate = computed(() => {
  return dayjs(props.modelValue.dateRange.end).format('DD-MMM-YY')
})

const childrenValid = computed(() => {
  for (let i = 0; i < props.modelValue.rooms.length; i++) {
    const room = props.modelValue.rooms[i]
    for (let j = 0; j < room.children; j++) {
      const age = room.childrenAges[j]
      if (!age || age < 0 || age > 17)
        return false
    }
  }
  return true
})

const numberOfAdults = computed(() => {
  let adults = 0
  for (let i = 0; i < props.modelValue.rooms.length; i++) {
    const room = props.modelValue.rooms[i]
    adults += room.adults
  }
  return adults
})

const numberOfChildren = computed(() => {
  let children = 0
  for (let i = 0; i < props.modelValue.rooms.length; i++) {
    const room = props.modelValue.rooms[i]
    children += room.children
  }
  return children
})

const allChildrenAges = computed(() => {
  const allAges = []
  for (let i = 0; i < props.modelValue.rooms.length; i++) {
    const room = props.modelValue.rooms[i]
    if (room.childrenAges.length) {
      for (let j = 0; j < room.childrenAges.length; j++)
        allAges.push(room.childrenAges[j])
    }
  }
  return allAges
})

const locale = computed(() => {
  if (currentPage.value?.language_code !== 'en')
    return 'es-MX'

  return 'en-US'
})

const gaclientid = computed(() => {
  let id = ''
  // don't want to break when ga is not available
  try {
    id = window.ga.getByName('Playa_Resorts').get('clientId')
  }
  catch (err) {
    // console.log(err)
  }
  return id
})

const finalUrl = computed(() => {
  if (currentPage.value?.site_id === 9)
    return `${props.modelValue.destination?.booking_settings.reservhotel_hotel_url}`

  if (
    !props.modelValue.destination?.booking_settings
      ?.reservhotel_hotel_url_direct
  ) {
    return ''
  }

  return `${props.modelValue.destination?.booking_settings.reservhotel_hotel_url_direct}`
})

const localRate = ref(props.rate)
const localPromo = ref(props.promo)
const localCurrency = ref(props.currency)

const bookingWidgetStore = useBookingWidgetStore()
const resortPromoCodes = bookingWidgetStore.resortPromoCodes

function attemptToSetPromoCodeForResort() {
  const newPromoCode = resortPromoCodes.find(
    element => element.resort_id === props.modelValue.destination.id,
  )

  if (newPromoCode) {
    switch (newPromoCode.type_reservhotel) {
      case 'rate':

        localRate.value = newPromoCode.value_reservhotel
        break
      case 'promo':
        localPromo.value = newPromoCode.value_reservhotel
        break
    }
    if (newPromoCode.currency)
      localCurrency.value = newPromoCode.currency
  }
}

function validateForReservHotel() {
  return new Promise((resolve, reject) => {
    const error: {
      title: string | null
      message: string | null
    } = {
      title: null,
      message: null,
    }

    if (
      currentPage.value?.site_id === 9
      && props.mbrAgentData.iata.length < 4
    ) {
      error.title = 'IATA Required'
      error.message
                        = 'Your IATA number should contain 8 digits. Please re-enter your IATA number.'
      emit('setError', error.title, error.message)
    }

    if (!props.modelValue.from && currentPage.value?.site_id !== 9) {
      error.title = 'Airport Required '
      error.message
                        = 'Airport required for flight. Please select an airport.'
      emit('setError', error.title, error.message)
    }

    if (Number.parseInt(props.modelValue.destination.booking_settings.max_total) < (Number.parseInt(numberOfAdults.value) + Number.parseInt(numberOfChildren.value))) {
      error.title = 'Traveler limit exceeded '
      error.message
                        = `Please adjust to a maximum of ${props.modelValue.destination.booking_settings.max_total}.`
      reject(error)
    }

    if (!props.modelValue.destination) {
      error.title = 'Destination Required '
      error.message
                        = 'Destination required for flight. Please select an destination.'
      emit('setError', error.title, error.message)
    }
    if (!departureDate.value || !returnDate.value) {
      error.title = 'Travel Dates Required '
      error.message
                        = 'Travel Dates required for flight. Please select travel dates.'
      emit('setError', error.title, error.message)
    }

    if (childrenValid.value === false) {
      error.title = 'Children Ages Required '
      error.message
                        = 'Please enter the ages of all children in your party.'
      emit('setError', error.title, error.message)
    }

    if (error.title)
      reject(error)
    else resolve(true)
  })
}

function submitToReservHotel() {
  validateForReservHotel()
    .then(() => {
      showLoading.value = true
      reservhotelform.value?.submit()
    })
    .catch((error) => {
      emit('setError', error.title, error.message)
    })
}

watchEffect(() => {
  attemptToSetPromoCodeForResort()
})

attemptToSetPromoCodeForResort()
</script>

<template>
  <div class="text-center clear-both reservhotel">
    <form ref="reservhotelform" :action="finalUrl" method="GET">
      <input type="hidden" name="aff" :value="mbrAgentData.iata">
      <input
        v-if="modelValue.mode === 'resort-flight'"
        type="hidden"
        name="airport"
        :value="modelValue.from"
      >
      <input
        v-if="modelValue.mode === 'resort-flight'"
        type="hidden"
        name="airportTo"
        :value="modelValue.destination?.booking_settings?.airportTo"
      >
      <input v-else type="hidden" name="showhotel" value="Y">
      <input
        v-if="
          currentPage?.site_id === 9
        "
        type="hidden"
        name="hotel"
        :value="`${modelValue.destination?.booking_settings.reservhotel_hotel_number}`"
      >
      <input
        v-else
        type="hidden"
        name="hotel"
        :value="`${modelValue.destination?.booking_settings.reservhotel_hotel_number_direct}`"
      >
      <input type="hidden" name="rooms" :value="modelValue.rooms.length">
      <input type="hidden" name="aDate" :value="departureDate">
      <input type="hidden" name="dDate" :value="returnDate">
      <input type="hidden" name="agent" :value="mbrAgentData.email">
      <input v-if="!mbrAgentData" type="hidden" name="pc" :value="localPromo">
      <input v-if="!mbrAgentData" type="hidden" name="currency" :value="localCurrency">
      <input v-if="!mbrAgentData" type="hidden" name="rategroup" :value="props.group">
      <input type="hidden" name="adults" :value="numberOfAdults">
      <input type="hidden" name="child" :value="numberOfChildren">
      <input
        v-for="(age, key) in allChildrenAges"
        :key="key"
        type="hidden"
        name="childages"
        :value="age"
      >
      <input type="hidden" name="lang" value="1">
      <button
        type="submit"
        class=" text-white px-6 py-3 uppercase text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed"
        :style="{
          backgroundColor: accentColor.value,
        }"
        @click.prevent="submitToReservHotel"
      >
        <span v-if="showLoading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 inline-block animate-spin mr-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>

          Searching
        </span>
        <span v-else class="whitespace-nowrap">{{ $t('book-now') }}</span>
      </button>
    </form>

    <div
      v-if="debug"
      class="lg:absolute lg:top-0 lg:right-0 flex flex-col space-y-6 mt-32 rounded p-4 text-white text-center"
      :style="{
        backgroundColor: accentColor.value,
      }"
    >
      <div class="text-lg font-bold">
        ReservHotel Submit
      </div>
      <div class="grid grid-cols-6 gap-x-4 gap-y-1 text-xs">
        <div class="col-span-1 text-right">
          Final Url:
        </div>
        <div class="col-span-5 font-bold text-left">
          {{ finalUrl }}
        </div>
        <div class="col-span-3 text-right">
          Mode:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ modelValue.mode }}
        </div>
        <div class="col-span-3 text-right">
          Airport:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ modelValue.from }}
        </div>
        <div class="col-span-3 text-right">
          Destination:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ modelValue.destination?.name }}
        </div>
        <div class="col-span-3 text-right">
          Adults Only:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{
            modelValue.destination?.booking_settings?.adults_only
              ? 'Yes'
              : 'No'
          }}
        </div>
        <div class="col-span-3 text-right">
          Max Adults:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ modelValue.destination?.booking_settings?.max_adults }}
        </div>
        <div class="col-span-3 text-right">
          Max Children:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ modelValue.destination?.booking_settings?.max_children }}
        </div>
        <div class="col-span-3 text-right">
          Max Total:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ modelValue.destination?.booking_settings?.max_total }}
        </div>
        <div class="col-span-3 text-right">
          Min Date:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{
            modelValue.destination?.booking_settings?.min_booking_date
          }}
        </div>
        <div class="col-span-3 text-right">
          Max Date:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{
            modelValue.destination?.booking_settings?.max_booking_date
          }}
        </div>
        <div class="col-span-3 text-right">
          Max Days Out:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ modelValue.destination?.booking_settings?.max_days_out }}
        </div>
        <div class="col-span-3 text-right">
          Reservhotel Enabled:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{
            modelValue.destination?.booking_settings
              ?.reservhotel_enabled
              ? 'Yes'
              : 'No'
          }}
        </div>
        <div class="col-span-3 text-right">
          Reservhotel Number:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{
            modelValue.destination?.booking_settings
              ?.reservhotel_hotel_number
          }}
        </div>
        <div class="col-span-3 text-right">
          Reservhotel Number Direct:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{
            modelValue.destination?.booking_settings
              ?.reservhotel_hotel_number_direct
          }}
        </div>
        <div class="col-span-3 text-right">
          Flights enabled:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{
            modelValue.destination?.booking_settings?.flights_enabled
              ? 'Yes'
              : 'No'
          }}
        </div>
      </div>
      <div class="grid grid-cols-6 gap-x-4 gap-y-1 text-xs">
        <div class="col-span-3 text-right">
          Agent IATA:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ mbrAgentData.iata }}
        </div>
        <div class="col-span-3 text-right">
          Agent:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ `${mbrAgentData.name},${mbrAgentData.iata}` }}
        </div>
      </div>
      <div class="grid grid-cols-6 gap-x-4 gap-y-1 text-xs">
        <div class="col-span-3 text-right">
          Theme Code:
        </div>
        <div class="col-span-3 font-bold text-left">
          No theme code
        </div>
      </div>
      <div class="grid grid-cols-6 gap-x-4 gap-y-1 text-xs">
        <div class="col-span-3 text-right">
          Number of Adults:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ numberOfAdults }}
        </div>
        <div class="col-span-3 text-right">
          Number of Children:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ numberOfChildren }}
        </div>
        <div class="col-span-3 text-right">
          Arrive:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ departureDate }}
        </div>
        <div class="col-span-3 text-right">
          Depart:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ returnDate }}
        </div>
        <div class="col-span-3 text-right">
          Hotel (not used):
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ props.preferredHotelCode }}
        </div>
        <div class="col-span-3 text-right">
          Rooms:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ props.modelValue.rooms.length }}
        </div>
        <div class="col-span-3 text-right">
          Rate:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ localRate }}
        </div>
        <div class="col-span-3 text-right">
          Promo:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ localPromo }}
        </div>
        <div class="col-span-3 text-right">
          Group:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ group }}
        </div>
        <div class="col-span-3 text-right">
          Locale:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ locale }}
        </div>
        <div class="col-span-3 text-right">
          Chain:
        </div>
        <div class="col-span-3 font-bold text-left">
          No Chain
        </div>
        <div class="col-span-3 text-right">
          Currency:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ localCurrency }}
        </div>
        <div class="col-span-3 text-right">
          gaclientid:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ gaclientid }}
        </div>
        <div class="col-span-3 text-right">
          mn:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ props.mn }}
        </div>
        <div class="col-span-3 text-right">
          SBE?:
        </div>
        <div class="col-span-3 font-bold text-left">
          {{ currentPage?.site_id === 14 ? 'Yes' : 'No' }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
