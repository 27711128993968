<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import type { BookingRoomInterface } from '@playa/types'

interface Props {
  modelValue: {
    mode: 'resort-only' | 'resort-flight'
    from: string
    destination: any
    dateRange: {
      start: string
      end: string
    }
    rooms: [
      {
        id: string
        adults: number
        children: number
        childrenAges: number[]
      },
    ]
  }
  minimize: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => ({
    mode: 'resort-only',
    from: '',
    destination: null,
    dateRange: {
      start: '',
      end: '',
    },
    rooms: [
      {
        id: 'room-1',
        adults: 2,
        children: 0,
        childrenAges: [],
      },
    ],
  }),
  minimize: false,
})

const emit = defineEmits(['input', 'addRoom', 'removeRoom', 'setAge'])
const { t } = useI18n()

// Accent color that comes in from the inline booking slice
const accentColor: { value: string } = inject('accentColor') as { value: string }

// Local Booking Variable
const localBooking = computed({
  get: () => props.modelValue,
  set: newValue => emit('input', newValue),
})

const label = computed(() => {
  let label = ''

  const totalAdults = localBooking.value.rooms.reduce((acc, room) => {
    return acc + room.adults
  }, 0)
  const totalChildren = localBooking.value.rooms.reduce(
    (acc, room) => {
      return acc + room.children
    },
    0,
  )

  if (localBooking.value.rooms.length > 1)
    label += `${localBooking.value.rooms.length} rooms, `
  else label += `${localBooking.value.rooms.length} room, `

  if (totalAdults > 1)
    label += `${totalAdults} ${t('adults')}`
  else label += `${totalAdults} ${t('adult')}`

  if (totalChildren > 0) {
    if (totalChildren > 1)
      label += `, ${totalChildren} ${t('children')}`
    else label += `, ${totalChildren} ${t('child')}`
  }

  return label
})

const state = reactive({
  isFocused: false,
})

function focus() {
  state.isFocused = true
  if (document && typeof window !== 'undefined') {
    const bookingPartyFormEl = document.getElementById('bookingPartyForm')
    if (bookingPartyFormEl) {
      const boundingRect = bookingPartyFormEl.getBoundingClientRect()

      window.scrollTo({
        top: window.scrollY + boundingRect.top - 300,
        behavior: 'smooth',
      })
    }
  }
}

function loseFocus() {
  state.isFocused = false
}

function addAdultsToRoom(room: BookingRoomInterface) {
  const maxAdults = Number.parseInt(
    localBooking.value.destination.booking_settings.max_adults,
    10,
  )
  if (room.adults < maxAdults)
    room.adults++
}

function removeAdultsFromRoom(room: BookingRoomInterface) {
  room.adults--
  if (room.adults <= 0)
    room.adults = 1
}

function addChildrenToRoom(room: BookingRoomInterface) {
  const maxChildren = Number.parseInt(
    localBooking.value.destination.booking_settings
      .max_children,
    10,
  )
  if (room.children < maxChildren)
    room.children++
}

function removeChildrenFromRoom(room: BookingRoomInterface) {
  room.children--
  if (room.children < 0)
    room.children = 0
}
</script>

<template>
  <div id="bookingPartyForm" class="relative">
    <input
      aria-label="Booking Party"
      :value="label"
      readonly
      type="text"
      class="px-0 py-1 text-sm w-full lg:w-[200px] border-0 border-b-2 border-gray-300 bg-abs-white font-medium text-playa-dark-blue focus:border-playa-action focus:outline-none focus:ring-0"
      :disabled="!localBooking.destination"
      @click="focus"
    >
    <div
      v-if="state.isFocused"
      v-on-click-outside="loseFocus"
      class="lg:absolute z-20 lg:top-0 lg:left-0 lg:translate-y-12 lg:-translate-x-12 bg-white rounded mt-3 lg:mt-0 lg:p-6 lg:w-[350px] lg:overflow-hidden"
    >
      <div>
        <div
          class="flex flex-col max-h-[300px] overflow-y-scroll w-[375px]"
        >
          <div
            v-for="(room, roomCount) in localBooking.rooms"
            :key="room.id"
            class="full-width-inputs clear-both w-[300px] text-sm"
          >
            <div
              class="mb-2 pb-1 border-b-4 font-medium uppercase text-xs"
              :style="{
                borderColor: accentColor.value,
                color: accentColor.value,
              }"
            >
              {{ $t('room') }} {{ roomCount + 1 }}
            </div>
            <div
              v-if="localBooking.destination"
              class="grid grid-cols-2 gap-y-4"
            >
              <div>{{ $t('adults') }}</div>
              <div
                class="flex items-center justify-end space-x-6"
              >
                <button
                  class=" text-white w-8 h-8 rounded flex justify-center items-center"
                  :style="{
                    backgroundColor: accentColor.value,
                  }"
                  @click="removeAdultsFromRoom(room)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M19.5 12h-15"
                    />
                  </svg>
                </button>
                <div class="font-bold">
                  {{ room.adults }}
                </div>
                <button
                  class="text-white w-8 h-8 rounded flex justify-center items-center"
                  :style="{
                    backgroundColor: accentColor.value,
                  }"
                  @click="addAdultsToRoom(room)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                </button>
              </div>
              <template
                v-if="
                  !localBooking.destination.booking_settings
                    .adults_only
                "
              >
                <div>
                  <div class="font-medium">
                    Children
                  </div>
                  <div class="text-2xs leading-[1em]">
                    {{ $t('ages') }} 0 to 12
                  </div>
                </div>
                <div
                  class="flex items-center justify-end space-x-6"
                >
                  <button
                    class=" text-white w-8 h-8 rounded flex justify-center items-center"
                    :style="{
                      backgroundColor: accentColor.value,
                    }"
                    @click="removeChildrenFromRoom(room)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M19.5 12h-15"
                      />
                    </svg>
                  </button>
                  <div class="font-bold">
                    {{ room.children }}
                  </div>
                  <button
                    class=" text-white w-8 h-8 rounded flex justify-center items-center"
                    :style="{
                      backgroundColor: accentColor.value,
                    }"
                    @click="addChildrenToRoom(room)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </button>
                </div>
              </template>
            </div>
            <div class="mt-4 grid grid-cols-2 gap-4 w-full">
              <div
                v-for="n in room.children"
                :key="`${room.id}-${n}`"
              >
                <select
                  class="rounded text-playa-dark-blue text-sm font-md appearance-none py-1.5 p-3"
                  :value="
                    localBooking.rooms[roomCount]
                      .childrenAges[n - 1]
                  "
                  @change="
                    $emit(
                      'setAge',
                      room,
                      n - 1,
                      $event.target.value,
                    )
                  "
                >
                  <option value="" disabled selected>
                    Child {{ n }} age
                  </option>
                  <option value="0">
                    0
                  </option>
                  <option
                    v-for="age in 12"
                    :key="age"
                    :value="age"
                  >
                    {{ age }}
                  </option>
                </select>
              </div>
            </div>
            <div
              v-if="localBooking.rooms.length > 1"
              class="col-span-2 text-right font-bold"
              :style="{
                color: accentColor.value,
              }"
            >
              <button @click="$emit('removeRoom', room)">
                Remove room
              </button>
            </div>
          </div>
        </div>

        <div class="flex justify-end space-x-4 mt-3">
          <button
            class="border font-medium rounded py-2 px-5 text-sm"
            :style="{
              borderColor: accentColor.value,
              color: accentColor.value,
            }"
            @click="$emit('addRoom')"
          >
            Add Another Room
          </button>
          <button
            class=" text-white font-medium rounded py-2 px-5 text-sm"
            :style="{
              backgroundColor: accentColor.value,
            }"
            @click="state.isFocused = false"
          >
            {{ $t('done') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
