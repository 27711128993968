<!-- eslint-disable no-extend-native -->
<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { DatePicker } from 'v-calendar'
import dayjs from 'dayjs'

import 'v-calendar/style.css'

import type { BlackoutDatesAttributesInterface } from '@playa/types'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

interface Props {
  modelValue: {
    mode: 'resort-only' | 'resort-flight'
    from: string
    destination: any
    dateRange: {
      start: string
      end: string
    }
    rooms: [
      {
        id: string
        adults: number
        children: number
        childrenAges: number[]
      },
    ]
  }
  minimize: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => ({
    mode: 'resort-only',
    from: '',
    destination: null,
    dateRange: {
      start: '',
      end: '',
    },
    rooms: [
      {
        id: 'room-1',
        adults: 2,
        children: 0,
        childrenAges: [],
      },
    ],
  }),
  minimize: false,
})

const emit = defineEmits(['updateDateRange'])

const bookingWidgetStore = useBookingWidgetStore()
const firstDaySelected = ref(false)
const datepicker = ref('datepicker')

const localDateRange = computed({
  get: () => props.modelValue.dateRange,
  set: (newValue) => {
    emit('updateDateRange', newValue)
  },
})

const bookingDateLimits = computed(() => {
  return bookingWidgetStore.bookingDateLimits
})

const minDate = computed(() => {
  let minDate = dayjs().toDate()

  if (bookingDateLimits.value.min)
    minDate = dayjs(bookingDateLimits.value.min).toDate()

  if (
    props.modelValue.destination
    && props.modelValue.destination.booking_settings.min_booking_date != null
  )
    minDate = dayjs(props.modelValue.destination.booking_settings.min_booking_date).toDate()

  return minDate
})

const maxDate = computed(() => {
  if (!props.modelValue.destination)
    return dayjs().add(1, 'day').toDate()

  const daysOut = props.modelValue.destination.booking_settings.max_days_out
  let maxDate = dayjs().add(Number.parseInt(daysOut), 'days').toDate()

  if (bookingDateLimits.value.max)
    maxDate = dayjs(bookingDateLimits.value.max).toDate()

  return maxDate
})

interface CalendarAttributeInterface {
  dates: Array<{
    start: Date
    end: Date
  }>
  disabled?: boolean
  highlight?: {
    backgroundColor: string
  }
  popover?: {
    label: string
  }
  contentStyle?: {
    color: string
  }
}

const highlightedDates = computed(() => {
  let dates: Array<BlackoutDatesAttributesInterface> = []

  if (props.modelValue.destination) {
    const bod = props.modelValue.destination.black_out_dates
    for (let i = 0; i < bod.length; i++) {
      const attribute: BlackoutDatesAttributesInterface = { dates: [] }
      attribute.dates.push({
        start: dayjs(bod[i].start_date).toDate(),
        end: dayjs(bod[i].end_date).toDate(),
      })

      if (bod[i].type === 'sold_out') {
        attribute.disabled = true
        attribute.highlight = {
          color: 'red',
          fillMode: 'outline',
        }
        attribute.contentStyle = {
          color: 'red',
        }
        attribute.popover = {
          label: bod[i].message,
        }
      }

      if (bod[i].type === 'restriction') {
        attribute.highlight = {
          color: 'yellow',
          fillMode: 'outline',
        }
        attribute.popover = {
          label: bod[i].message,
        }
      }

      dates = dates.concat(attribute)
    }
  }

  return dates
})

const disabledDates = computed(() => {
  const dates: Array<{ start: Date, end: Date }> = []

  if (props.modelValue.destination) {
    const bod = props.modelValue.destination.black_out_dates
    for (let i = 0; i < bod.length; i++) {
      if (bod[i].type === 'sold_out') {
        if (firstDaySelected.value) {
          // If the first day is selected and is before the blackout date then we can shift the start date of the black out one day.
          if (dayjs(props.modelValue.dateRange.start).isBefore(dayjs(bod[i].start_date), 'day')) {
            dates.push({
              start: dayjs(bod[i].start_date).add(1, 'day').toDate(),
              end: dayjs(bod[i].end_date).add(1, 'day').toDate(),
            })
          }
          else {
            dates.push({
              start: dayjs(bod[i].start_date).toDate(),
              end: dayjs(bod[i].end_date).toDate(),
            })
          }
        }
        else {
          dates.push({
            start: dayjs(bod[i].start_date).toDate(),
            end: dayjs(bod[i].end_date).toDate(),
          })
        }
      }
    }
  }

  return dates
})

const { t } = useI18n()
const getKeys = computed(() => {
  return [
    {
      class: 'sold-out',
      label: t('soldOut'),
    },
    {
      class: 'restriction',
      label: t('restriction'),
    },
  ]
})

const { $voix } = useNuxtApp()
const numberOfColumns = computed(() => {
  if ($voix.breakpoint.isLessThan('xl'))
    return 1

  return 2
})

function popoverDidAppear() {
  const svgs = document.getElementsByTagName('svg')
  for (let i = 0; i < svgs.length; i++)
    svgs[i].setAttribute('focusable', 'false')
}
const inputPlaceholder = computed(() => {
  if(props.modelValue?.dateRange?.start && props.modelValue?.dateRange?.start)
    return dayjs(props.modelValue.dateRange.start).format('MMM D, YYYY') + ' - ' + dayjs(props.modelValue.dateRange.end).format('MMM D, YYYY')

  return 'SELECT DATES'
})
</script>

<template>
  <div class="relative">
    <DatePicker
      ref="datepicker"
      v-model.range="localDateRange"
      mode="date"
      popover-direction="bottom"
      :popover-keep-visible-on-input="true"
      :popover-content-offset="0"
      popover-align="center"
      popover-visibility="focus"
      :columns="numberOfColumns"
      :pane-width="350"
      :min-date="minDate"
      :max-date="maxDate"
      :disabled-dates="disabledDates"
      :attributes="highlightedDates"
      @dayclick="firstDaySelected = !firstDaySelected"
      @popover-will-appear="firstDaySelected = false"
      @popover-did-appear="popoverDidAppear"
    >
      <template #default="slotProps">
        <!-- TODO :attributes="highlightedDates" -->
        <div class="text-left opacity-0" @click.prevent="slotProps.togglePopover">
          <input
            v-if="props.modelValue.destination"
            :value="slotProps.inputValue.start ? `${slotProps.inputValue.start} - ${slotProps.inputValue.end}` : 'SELECT DATES'"
            type="text"
            readonly
            class="px-0 py-1 text-sm w-full lg:w-[180px] border-0 border-b-2 bg-abs-white border-gray-300 tracking-wide focus:border-playa-action focus:outline-none focus:ring-0 text-left"
          >
          <input
            v-else
            disabled
            value=""
            type="text"
            readonly
            class="px-0 py-1 text-sm w-full lg:w-[180px] border-0 border-b-2 bg-abs-white border-gray-300 tracking-wide focus:border-playa-action focus:outline-none focus:ring-0 text-left"
          >
        </div>
      </template>
    </DatePicker>
      <div class="text-left absolute inset-0 pointer-events-none">
        <input
          v-if="props.modelValue.destination"
          :value="inputPlaceholder"
          type="text"
          readonly
          class="px-0 py-1 text-sm w-full lg:w-[180px] border-0 border-b-2 bg-abs-white border-gray-300 tracking-wide focus:border-playa-action focus:outline-none focus:ring-0 text-left"
        >
        <input
          v-else
          disabled
          value=""
          type="text"
          readonly
          class="px-0 py-1 text-sm w-full lg:w-[180px] border-0 border-b-2 bg-abs-white border-gray-300 tracking-wide focus:border-playa-action focus:outline-none focus:ring-0 text-left"
        >
      </div>
  </div>
</template>
