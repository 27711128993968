<script setup lang="ts">
import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
import { usePageStore } from '@voix/store/pageStore'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

interface Props {
  debug: boolean
  modelValue: {
    mode: 'resort-only' | 'resort-flight'
    from: string
    destination: any
    dateRange: {
      start: string | Date
      end: string | Date
    }
    rooms: [
      {
        id: string
        adults: number
        children: number
        childrenAges: number[]
      },
    ]
  }
  error: {
    title: string
    message: string
  }
  mbrAgentData: {
    iata: string
    name: string
    email: string
  }
  preferredHotelCode: string
  disabledDates: string[]
  rate: string
  promo: string
  coupon?: string
  group: string
  currency: string
  mn: string
  showAgentFields: boolean
  discounts: {
    coupon: string | null
    promo: string | null
    rate: string | null
  }
}

const props = withDefaults(defineProps<Props>(), {
  debug: false,
  modelValue: () => ({
    mode: 'resort-only',
    from: '',
    destination: null,
    dateRange: {
      start: '',
      end: '',
    },
    rooms: [
      {
        id: 'room-1',
        adults: 2,
        children: 0,
        childrenAges: [],
      },
    ],
  }),
  error: () => ({
    title: '',
    message: '',
  }),
  mbrAgentData: () => ({
    iata: '',
    name: '',
    email: '',
  }),
  preferredHotelCode: '',
  disabledDates: () => [],
  rate: '',
  promo: '',
  coupon: '',
  group: '',
  currency: 'USD',
  mn: '',
  showAgentFields: false,
  discounts: () => ({
    coupon: null,
    promo: null,
    rate: null,
  }),
})

const emit = defineEmits(['input', 'clearError', 'setError'])

// Accent color that comes in from the inline booking slice
const accentColor: { value: string } = inject('accentColor') as { value: string }

const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})

// Local Booking Variable
const localBooking = computed({
  get: () => props.modelValue,
  set: newValue => emit('input', newValue),
})

const action = computed(() => {
  if (props.modelValue?.destination?.synxis_url)
    return props.modelValue?.destination?.synxis_url

  if (
    props.modelValue.destination?.id !== 35
    && props.modelValue.destination?.id !== 36
    && props.modelValue.destination?.id !== 38
    && props.modelValue.destination?.id !== 43
    && props.modelValue.destination?.id !== 44
  ) {
    return 'https://be.synxis.com/'
  }

  return 'https://be-p2.synxis.com/'
})
const showLoading = ref(false)

const configcode = ref('PlayaWebsite')
const themecode = ref('PlayaWebsite')

const departureDate = computed(() => {
  return dayjs(props.modelValue.dateRange.start).format('YYYY-MM-DD')
})

const returnDate = computed(() => {
  return dayjs(props.modelValue.dateRange.end).format('YYYY-MM-DD')
})

const numberOfAdults = computed(() => {
  let adults = ''
  for (let i = 0; i < props.modelValue.rooms.length; i++) {
    const room = props.modelValue.rooms[i]
    if (i > 0)
      adults += ','

    adults += room.adults.toString()
  }
  return adults
})

const numberOfChildren = computed(() => {
  let children = ''
  for (let i = 0; i < props.modelValue.rooms.length; i++) {
    const room = props.modelValue.rooms[i]
    if (i > 0)
      children += ','

    children += room.children.toString()
  }
  return children
})

function validateForKimpton() {
  const error: {
    title: string | null
    message: string | null
  } = {
    title: null,
    message: null,
  }
  return new Promise((resolve, reject) => {
    if (!props.modelValue.destination) {
      error.title = 'Destination Required '
      error.message
                        = 'Destination required for flight booking. Please select an destination.'
      reject(error)
    }
    if (!departureDate.value || !returnDate.value) {
      error.title = 'Travel Dates Required '
      error.message
                        = 'Travel Dates required for flight booking. Please select travel dates.'
      reject(error)
    }

    const bookStart = props.modelValue.dateRange.start
    const bookEnd = props.modelValue.dateRange.end

    if (dayjs(bookStart).isSame(bookEnd)) {
      error.title = 'Invalid Check-out Date'
      error.message
                        = 'You have selected a date range that contains a sold out date. Please change your selections to continue'

      setTimeout(() => {
        localBooking.value.dateRange.end = dayjs(
          props.modelValue.dateRange.start,
        )
          .add(1, 'day')
          .toDate()
      }, 500)
      showLoading.value = false
      reject(error)
    }

    resolve(true)
  })
}

const synaxisForm: Ref<HTMLFormElement | null> = ref(null)

function submitToSynaxis() {
  validateForKimpton()
    .then(() => {
      emit('clearError')
      showLoading.value = true
      synaxisForm.value?.submit()
    })
    .catch((error) => {
      emit('setError', error.title, error.message)
    })
}

const bookingWidgetStore = useBookingWidgetStore()
const promoCodes = computed(() => {
  return bookingWidgetStore.promoCodes
})
const promoCodeWasSetBySlice = ref(false)

const localRate = ref(props.rate)
const localPromo = ref(props.promo)
const localCoupon = ref(props.coupon)
const localCurrency = ref(props.currency)

function attemptToSetPromoCodeForResort() {
  const resortPromoCodes = bookingWidgetStore.resortPromoCodes

  if (!promoCodeWasSetBySlice.value && resortPromoCodes) {
    const newPromoCode = resortPromoCodes.find(
      element =>
        element.resort_id === props.modelValue.destination?.id,
    )
    if (typeof newPromoCode !== 'undefined' && newPromoCode) {
      switch (newPromoCode.type) {
        case 'rate':
          localRate.value = newPromoCode.value
          break
        case 'promo':
          localPromo.value = newPromoCode.value
          break
        case 'coupon':
          localCoupon.value = newPromoCode.value
          break
      }
      if (newPromoCode.currency)
        localCurrency.value = newPromoCode.currency
    }
  }
  else {
    if (currentPage.value?.site_id === 12) {
      if (
        localRate.value.startsWith('tpc')
        && props.modelValue.destination?.id > 34
        && props.modelValue.destination?.id < 37
      ) {
        localRate.value = localRate.value.replace('tpc', 'spc')
      }

      else if (promoCodes.value.rate) {
        localRate.value = promoCodes.value.rate
      }
    }
  }
}

watchEffect(() => {
  attemptToSetPromoCodeForResort()
})

attemptToSetPromoCodeForResort()

if (promoCodes.value?.configcode)
  configcode.value = promoCodes.value.configcode

if (promoCodes.value.promo) {
  localPromo.value = promoCodes.value.promo
  promoCodeWasSetBySlice.value = true
}
if (promoCodes.value.coupon) {
  localCoupon.value = promoCodes.value.coupon
  promoCodeWasSetBySlice.value = true
}
if (promoCodes.value.rate) {
  localRate.value = promoCodes.value.rate
  promoCodeWasSetBySlice.value = true
}

const localGroup = ref(props.group)
if (promoCodes.value.group) {
  localGroup.value = promoCodes.value.group
  promoCodeWasSetBySlice.value = true
}
if (promoCodes.value.configcode)
  configcode.value = promoCodes.value.configcode

if (promoCodes.value.themecode)
  themecode.value = promoCodes.value.themecode

if (promoCodes.value.currency)
  localCurrency.value = promoCodes.value.currency

if (props.discounts.rate) {
  localRate.value = props.discounts.rate
  promoCodeWasSetBySlice.value = true
}

watch(promoCodes, async (newCodes) => {
  if (newCodes.promo) {
    localPromo.value = newCodes.promo
    promoCodeWasSetBySlice.value = true
  }
  if (newCodes.coupon) {
    localCoupon.value = newCodes.coupon
    promoCodeWasSetBySlice.value = true
  }
  if (newCodes.rate) {
    localRate.value = newCodes.rate
    promoCodeWasSetBySlice.value = true
  }
  if (newCodes.group) {
    localGroup.value = newCodes.group
    promoCodeWasSetBySlice.value = true
  }
})

const checkInDate = computed(() => {
  return dayjs(props.modelValue.dateRange.start).format('DD')
})

const checkInMonthYear = computed(() => {
  let month: string | number = Number.parseInt(dayjs(props.modelValue.dateRange.start).format('MM')) - 1
  const year: string = dayjs(props.modelValue.dateRange.start).format('YYYY')

  if (month < 10)
    month = `0${month}`

  return `${month}${year}`
})

const checkOutDate = computed(() => {
  return dayjs(props.modelValue.dateRange.end).format('DD')
})

const checkOutMonthYear = computed(() => {
  let month: string | number = Number.parseInt(dayjs(props.modelValue.dateRange.end).format('MM')) - 1
  const year: string = dayjs(props.modelValue.dateRange.end).format('YYYY')

  if (month < 10)
    month = `0${month}`

  return `${month}${year}`
})
</script>

<template>
  <div class="text-center clear-both">
    <form
      ref="synaxisForm"
      action="https://www.kimptonhotels.com/redirect"
      method="GET"
      @submit.prevent="validateForKimpton()"
    >
      <input type="hidden" name="path" value="asearch">
      <input type="hidden" name="hotelCode" value="PCMHT">
      <input type="hidden" name="brandCode" value="KI">
      <input type="hidden" name="localeCode" value="en">
      <input type="hidden" name="rateCode" value="IVANI">

      <input type="hidden" name="numberOfAdults" :value="numberOfAdults">
      <input type="hidden" name="numberOfChildren" :value="numberOfChildren">

      <input type="hidden" name="checkInDate" :value="checkInDate">
      <input type="hidden" name="checkInMonthYear" :value="checkInMonthYear">
      <input type="hidden" name="checkOutDate" :value="checkOutDate">
      <input type="hidden" name="checkOutMonthYear" :value="checkOutMonthYear">
      <input
        type="hidden"
        name="numberOfRooms"
        :value="props.modelValue.rooms.length"
      >

      <button
        type="submit"
        class=" text-white px-6 py-3 uppercase text-sm font-medium"
        :style="{
          backgroundColor: accentColor.value,
        }"
        @click.prevent="submitToSynaxis"
      >
        <span v-if="showLoading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 inline-block animate-spin mr-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>

          Searching
        </span>
        <span v-else class="whitespace-nowrap">{{ $t('book-now') }} </span>
      </button>
    </form>
  </div>
</template>
