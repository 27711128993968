<script>
import dayjs from 'dayjs'
import { computed, onMounted, ref } from 'vue'
import { v4 as uuid } from 'uuid'

import { usePageStore } from '@voix/store/pageStore'
import BookingAirport from './BookingAirport.vue'
import BookingCalendars from './BookingCalendars.vue'
import BookingDestination from './BookingDestination.vue'
import BookingParty from './BookingParty.vue'
import BookingSynaxisSubmit from './BookingSynaxisSubmit.vue'
import BookingToReservHotelSubmit from './BookingToReservHotelSubmit.vue'
import BookingKimptonSubmit from './BookingKimptonSubmit.vue'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

export default {
  name: 'FlightBookingForm',
  components: {
    BookingAirport,
    BookingCalendars,
    BookingDestination,
    BookingParty,
    BookingKimptonSubmit,
    BookingSynaxisSubmit,
    BookingToReservHotelSubmit,
  },
  props: {
    debug: {
      type: Boolean,
      default: false,
    },
    minimize: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Boolean,
      default: false,
    },
    mn: {
      type: String,
      default: '',
    },
    discounts: {
      type: Object,
      default() {
        return {
          coupon: null,
          promo: null,
          rate: null,
        }
      },
    },
    shiftOverHero: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // The Booking
    const ratePreference = ref('best-available')

    const currentResort = inject('currentResort')
    const currentResortInStaging = inject('currentResortInStaging')
    const booking = ref({
      mode: 'resort-only', // resort-only | resort-flight
      from: '',
      destination: null,
      dateRange: {
        start: null,
        end: null,
      },
      rooms: [
        {
          id: uuid(),
          adults: 2,
          children: 0,
          childrenAges: [],
        },
      ],
    })

    // Accent color that comes in from the inline booking slice
    const accentColor = inject('accentColor', { value: '#1d3091' })

    const neverShowFlight = ref(false)

    const bookingWidgetStore = useBookingWidgetStore()

    const currentPage = computed(() => {
      const pageStore = usePageStore()
      return pageStore.currentPage
    })

    // Errors
    const error = ref({
      title: '',
      message: '',
    })

    function clearError() {
      error.value.title = ''
      error.value.message = ''
    }

    function setError(title, message) {
      error.value.title = title
      error.value.message = message
    }

    const endDate = new Date()
    endDate.setDate(endDate.getDate() + 1)

    // AgentCashPlus
    const showAgentFields = computed(() => {
      return currentPage.value.site_id === 9
    })

    const mbrAgentData = ref({ iata: '', name: '', email: '' })

    const mbrDataCookie = useCookie('mbrAgentData')

    if (mbrDataCookie.value) {
      mbrAgentData.value.iata = mbrDataCookie.value.IATANumber
      mbrAgentData.value.name = mbrDataCookie.value.FirstName
      mbrAgentData.value.email = mbrDataCookie.value.Email
    }

    const mbrAgentIATA = computed(() => {
      return mbrDataCookie.value?.IATANumber
    })

    // Rate and Promos
    const promo = ref('')
    const coupon = ref('')
    const rate = ref('BARWEB')
    const group = ref('')
    const currency = ref('USD')

    const promoCodeWasSetBySlice = ref(false)
    const promoCodes = computed(() => {
      return bookingWidgetStore.promoCodes
    })
    const resortPromoCodes = computed(() => {
      return bookingWidgetStore.resortPromoCodes
    })

    async function getPromosUsingCodeTriggers() {
      const config = useRuntimeConfig()
      const route = useRoute()
      const search = new URLSearchParams(route.query).toString()
      let url = `${config.public.voixApiBaseUrl}/api/booking-codes/by-resort?${search}`

      if (currentResortInStaging)
        url += `&force_resort_id=${currentResort?.id}`

      await $fetch(url)
        .then((response) => {
          bookingWidgetStore.resortPromoCodes = response
        })
    }

    function resetDates() {
      booking.value.dateRange.start = dayjs().toDate()
      booking.value.dateRange.end = dayjs().add(1, 'day').toDate()
    }

    function resetRooms() {
      for (let i = 0; i < booking.value.rooms.length; i++) {
        const room = booking.value.rooms[i]
        room.adults = 2
        room.children = 0
        room.childrenAges = []
        removeRoom(booking.value.rooms[i])
      }

      attemptToSetPromoCodeForResort()
    }

    function removeRoom(room) {
      const min = !props.groups ? 1 : 5
      if (booking.value.rooms.length > min) {
        booking.value.rooms.splice(
          booking.value.rooms.indexOf(room),
          1,
        )
      }
    }

    function setAge(room, index, age) {
      if (room.childrenAges[index])
        room.childrenAges[index] = age
      else room.childrenAges.push(age)
    }

    function attemptToSetPromoCodeForResort() {
      if (!promoCodeWasSetBySlice.value) {
        const newPromoCode = bookingWidgetStore.resortPromoCodes?.find(
          promo => promo.resort_id === booking.value.destination?.id,
        )

        if (typeof newPromoCode !== 'undefined' && newPromoCode) {
          switch (newPromoCode.type) {
            case 'rate':
              rate.value = newPromoCode.value
              break
            case 'promo':
              promo.value = newPromoCode.value
              break
            case 'coupon':
              coupon.value = newPromoCode.value
              break
          }
          if (newPromoCode.currency)
            currency.value = newPromoCode.currency
        }
      }
      else {
        if (currentPage.value.site_id === 12) {
          if (
            rate.value.startsWith('tpc')
            && booking.value.destination
            && !Number.isNaN(booking.value.destination?.id)
            && booking.value.destination?.id > 34
            && booking.value.destination?.id < 37
          ) { rate.value = rate.value.replace('tpc', 'spc') }
          else {
            rate.value = promoCodes.value.rate
              ? promoCodes.value.rate
              : 'BARWEB'
          }
        }
      }
    }

    // Calendar Items
    const showReservHotelFormForACP = computed(() => {
      return (
        booking.value.destination
        // TODO: remove this when we have a better way to check
        // if it's an agentcashplus site
        && currentPage.value.site_id === 9
        && booking.value.destination.booking_settings
          .reservhotel_enabled
          && booking.value.destination.booking_settings
            .reservhotel_hotel_number
      )
    })

    const bookingDateLimits = computed(() => {
      return bookingWidgetStore.bookingDateLimits
    })

    const minDate = computed(() => {
      let minDate = dayjs().toDate()

      if (bookingDateLimits.value.min)
        minDate = dayjs(bookingDateLimits.value.min).toDate()

      if (
        booking.value.destination
        && booking.value.destination.booking_settings.min_booking_date
        != null
      ) {
        minDate = dayjs(
          booking.value.destination.booking_settings.min_booking_date,
        ).toDate()
      }

      return minDate
    })

    const maxDate = computed(() => {
      if (!booking.value.destination)
        return dayjs().add(1, 'day').toDate()

      const daysOut
                = booking.value.destination.booking_settings.max_days_out
      let maxDate = dayjs().add(Number.parseInt(daysOut), 'days').toDate()

      if (bookingDateLimits.value.max)
        maxDate = dayjs(bookingDateLimits.value.max).toDate()

      return maxDate
    })

    const firstDaySelected = ref(null)

    const disabledDates = computed(() => {
      let dates = []

      if (booking.value.destination) {
        const bod = booking.value.destination.black_out_dates
        for (let i = 0; i < bod.length; i++) {
          if (bod[i].type === 'sold_out') {
            if (firstDaySelected.value) {
              // If the first day is selected and is before the blackout date then we can shift the start date of the black out one day.
              if (
                dayjs(firstDaySelected.value).isBefore(
                  dayjs(bod[i].start_date),
                  'day',
                )
              ) {
                dates = dates.concat({
                  start: dayjs(bod[i].start_date)
                    .add(1, 'day')
                    .toDate(),
                  end: dayjs(bod[i].end_date)
                    .add(1, 'day')
                    .toDate(),
                })
              }
              else {
                dates = dates.concat({
                  start: dayjs(bod[i].start_date).toDate(),
                  end: dayjs(bod[i].end_date).toDate(),
                })
              }
            }
            else {
              dates = dates.concat({
                start: dayjs(bod[i].start_date).toDate(),
                end: dayjs(bod[i].end_date).toDate(),
              })
            }
          }
        }
      }

      return dates
    })

    const preferredHotelCode = computed(() => {
      if (showReservHotelFormForACP.value) {
        return booking.value.destination?.booking_settings
          .reservhotel_hotel_number
      }

      return booking.value.destination?.booking_settings.synxis_hotel_num
    })

    const acpLang = computed(() => {
      return currentPage.value.language_code
    })

    if (process.client)
      getPromosUsingCodeTriggers()

    if (promoCodes.value.promo) {
      promo.value = promoCodes.value.promo
      promoCodeWasSetBySlice.value = true
    }
    if (promoCodes.value.coupon) {
      coupon.value = promoCodes.value.coupon
      promoCodeWasSetBySlice.value = true
    }
    if (promoCodes.value.rate) {
      rate.value = promoCodes.value.rate
      promoCodeWasSetBySlice.value = true
    }
    if (promoCodes.value.group) {
      group.value = promoCodes.value.group
      promoCodeWasSetBySlice.value = true
    }

    function addRoom() {
      if (booking.value.rooms.length < 3) {
        booking.value.rooms.push({
          id: uuid(),
          adults: 1,
          children: 0,
          childrenAges: [],
        })
      }
    }

    function updateDateRange(newValue) {
      booking.value.dateRange.start = newValue.start
      booking.value.dateRange.end = newValue.end
    }

    function onFromInput(newFrom) {
      booking.value.from = newFrom
    }

    const submitFormType = computed(() => {
      if (currentResort?.id === 45 && ratePreference.value === 'reward-nights')
        return 'kimpton'

      if (showReservHotelFormForACP.value || booking.value.mode === 'resort-flight')
        return 'reservhotel'

      return 'synaxis'
    })

    return {
      submitFormType,
      ratePreference,
      currentResort,
      mbrAgentIATA,
      onFromInput,
      accentColor,
      booking,
      error,
      showAgentFields,
      mbrAgentData,
      promo,
      coupon,
      rate,
      group,
      currency,
      props,
      promoCodeWasSetBySlice,
      promoCodes,
      resortPromoCodes,
      showReservHotelFormForACP,
      bookingDateLimits,
      minDate,
      maxDate,
      firstDaySelected,
      disabledDates,
      preferredHotelCode,
      acpLang,
      neverShowFlight,
      updateDateRange,
      clearError,
      setError,
      resetDates,
      resetRooms,
      removeRoom,
      setAge,
      attemptToSetPromoCodeForResort,
      addRoom,
      currentPage,
    }
  },
}
</script>

<template>
  <div class="relative z-[9000] w-full lg:w-auto py-4" :class="{ '-translate-y-1/4 lg:-translate-y-full -mb-[150px]': props.shiftOverHero }">
    <div class="flex justify-between items-end px-4 lg:px-0">
      <div
        v-if="!neverShowFlight"
        class="flex space-x-4 pl-4 translate-y-[1px]"
      >
        <button
          v-if="
            booking.destination
              && (
                booking.destination?.booking_settings?.flights_enabled
                || currentPage?.site_id === 9
              )
          "
          class="flex items-center space-x-1 py-2 px-6 text-xs font-medium lg:duration-500 ease-out border-b-0"
          :class="{
            'bg-abs-white border border-gray-200':
              booking.mode === 'resort-only',
            'bg-grey-lightest text-white': booking.mode !== 'resort-only',
          }"
          :style="{
            color: accentColor.value,
          }"
          @click="booking.mode = 'resort-only'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 mr-2"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M0 32C0 14.3 14.3 0 32 0h448c17.7 0 32 14.3 32 32s-14.3 32-32 32v384c17.7 0 32 14.3 32 32s-14.3 32-32 32H304v-48c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64C14.3 64 0 49.7 0 32zm96 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16zm-240 80c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32zm-40 192c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8h144z"
            />
          </svg>
          <span>Hotel</span>
        </button>
        <button
          v-if="
            booking.destination
              && (
                booking.destination?.booking_settings?.flights_enabled
                || currentPage?.site_id === 9
              )
          "
          class="flex items-center space-x-1 py-2 px-6 text-xs font-medium lg:duration-500 ease-out border-b-0"
          :class="{
            'bg-abs-white tborder border-gray-300 ':
              booking.mode === 'resort-flight',
            'bg-grey-lightest text-white': booking.mode !== 'resort-flight',
          }"
          :style="{
            color: accentColor.value,
          }"
          @click="booking.mode = 'resort-flight'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6 mr-2"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M482.3 192c34.2 0 93.7 29 93.7 64c0 36-59.5 64-93.7 64H365.7L265.2 495.9c-5.7 10-16.3 16.1-27.8 16.1h-56.2c-10.6 0-18.3-10.2-15.4-20.4l49-171.6H112l-43.2 57.6c-3 4-7.8 6.4-12.8 6.4H14c-7.8 0-14-6.3-14-14c0-1.3.2-2.6.5-3.9L32 256L.5 145.9c-.4-1.3-.5-2.6-.5-3.9c0-7.8 6.3-14 14-14h42c5 0 9.8 2.4 12.8 6.4L112 192h102.9l-49-171.6c-3-10.2 4.7-20.4 15.3-20.4h56.2c11.5 0 22.1 6.2 27.8 16.1L365.7 192h116.6z"
            />
          </svg>
          <span>{{ $t('flightAndHotel') }}</span>
        </button>
      </div>

      <div
        v-if="error.title"
        class="bg-playa-dark-blue text-sm text-white px-8 py-3"
      >
        <div class="text-xs uppercase font-bold text-gray-400">
          {{ error.title }}
        </div>
        <div>
          {{ error.message }}
        </div>
      </div>

      <!-- TODO: Replace the div below with this  -->
      <div v-if="showAgentFields">
        <!-- <div> -->
        <input
          id="iata"
          v-model="mbrAgentIATA"
          placeholder="IATA (8 Character identification Code)"
          type="text"
          class="bg-white/80 placeholder:text-playa-dark-blue placeholder:uppercase text-xs py-3 px-6 font-medium border-0 border-b-2 lg:w-[400px] focus:ring-0 focus:ring-b-2"
          :style="{ borderColor: accentColor.value }"
        >
      </div>
    </div>
    <div class=" bg-abs-white shadow-lg border border-gray-300">
      <div
        v-if="currentResort?.id === 45"
        class="flex justify-start items-center py-2 border-b border-gray-100 mx-6"
      >
        <div class="text-[11px] uppercase font-medium text-playa-dark-blue pr-4">
          Rate Preference
        </div>
        <div class="w-1/2 lg:w-auto">
          <div class="border-0 border-b-2 border-gray-300 focus:border-abs-black focus:outline-none focus:ring-0">
            <select v-model="ratePreference" type="text" class="border-0 focus:border-0 focus:ring-0 py-1 text-sm w-full lg:w-[200px] font-medium">
              <option value="best-available">
                Best Available
              </option>
              <option value="reward-nights">
                Rewards Nights
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="px-8 py-4 lg:px-6 flex flex-col justify-stretch lg:flex-row lg:justify-between lg:space-x-8 space-y-8 lg:space-y-0 lg:mt-0"
      >
        <div
          v-if="booking.mode === 'resort-flight'"
          class="text-playa-dark-blue"
        >
          <div
            class="text-[11px] uppercase font-medium text-playa-dark-blue"
          >
            {{ $t('departureAirport') }}
          </div>
          <BookingAirport @input="onFromInput" />
        </div>
        <div class="text-playa-dark-blue">
          <div class="text-[11px] uppercase font-medium">
            {{ $t('destination') }}
          </div>
          <ClientOnly>
            <BookingDestination
              v-model="booking"
              :minimize="minimize"
              @turn-on-flight="booking.mode = 'resort-flight'"
              @never-show-flight="neverShowFlight = true"
              @reset-rooms="resetRooms"
              @reset-dates="resetDates"
            />
          </ClientOnly>
        </div>
        <div
          class="text-playa-dark-blue"
          :class="{
            'opacity-50 cursor-not-allowed  pointer-events-none':
              !booking.destination,
          }"
        >
          <div class="text-[11px] uppercase font-medium">
            {{ $t('checkInCheckOut') }}
          </div>
          <ClientOnly>
            <BookingCalendars
              v-model="booking"
              :first-day-selected="firstDaySelected"
              :error="error"
              :minimize="props.minimize"
              :groups="props.groups"
              :min-date="minDate"
              :max-date="maxDate"
              :disabled-dates="disabledDates"
              @update-date-range="updateDateRange"
            />
          </ClientOnly>
        </div>
        <div
          class="text-playa-dark-blue"
          :class="{
            'opacity-50 cursor-not-allowed pointer-events-none':
              !booking.destination,
          }"
        >
          <div class="text-[11px] uppercase font-medium">
            {{ $t('guests') }}
          </div>
          <BookingParty
            v-model="booking"
            :error="error"
            :minimize="minimize"
            :groups="groups"
            :group="group"
            @set-error="setError"
            @clear-error="clearError"
            @add-room="addRoom"
            @remove-room="removeRoom"
            @set-age="setAge"
          />
        </div>

        <div
          class="flex items-center justify-center"
          :style="{ backgroundColor: accentColor.value }"
        >
          <template v-if="submitFormType === 'synaxis'">
            <BookingSynaxisSubmit
              v-model="booking"
              :error="error"
              :debug="debug"
              :mbr-agent-data="mbrAgentData"
              :preferred-hotel-code="preferredHotelCode"
              :show-agent-fields="showAgentFields"
              :disabled-dates="disabledDates"
              :rate="rate"
              :promo="promo"
              :group="group"
              :currency="currency"
              :mn="mn"
              :discounts="props.discounts"
              @set-error="setError"
              @clear-error="clearError"
            />
          </template>

          <template v-if="submitFormType === 'reservhotel'">
            <BookingToReservHotelSubmit
              v-model="booking"
              :error="error"
              :debug="debug"
              :mbr-agent-data="mbrAgentData"
              :preferred-hotel-code="preferredHotelCode"
              :show-agent-fields="showAgentFields"
              :disabled-dates="disabledDates"
              :acp-lang="acpLang"
              :rate="rate"
              :promo="promo"
              :group="group"
              :coupon="coupon"
              :currency="currency"
              :mn="mn"
              :discounts="props.discounts"
              @set-error="setError"
              @clear-error="clearError"
            />
          </template>

          <template v-if="submitFormType === 'kimpton'">
            <BookingKimptonSubmit
              v-model="booking"
              :error="error"
              :debug="debug"
              :mbr-agent-data="mbrAgentData"
              :preferred-hotel-code="preferredHotelCode"
              :show-agent-fields="showAgentFields"
              :disabled-dates="disabledDates"
              :acp-lang="acpLang"
              :rate="rate"
              :promo="promo"
              :group="group"
              :coupon="coupon"
              :currency="currency"
              :mn="mn"
              :discounts="props.discounts"
              @set-error="setError"
              @clear-error="clearError"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
