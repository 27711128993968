<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
import { computed, reactive } from 'vue'
import dayjs from 'dayjs'

import { usePageStore } from '@voix/store/pageStore'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

interface Props {
  modelValue: BookingInterface
  minimize: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => ({
    mode: 'resort-only',
    from: '',
    destination: null,
    dateRange: {
      start: null,
      end: null,
    },
    rooms: [
      {
        id: 'room-1',
        adults: 2,
        children: 0,
        childrenAges: [],
      },
    ],
  }),

  minimize: false,
})

const emit = defineEmits(['update:modelValue', 'resetRooms', 'resetDates', 'neverShowFlight'])

const currentResort = inject('currentResort')
const currentResortInStaging = inject('currentResortInStaging')

const bookingWidgetStore = useBookingWidgetStore()

const state = reactive({
  isFocused: false,
  search: '',
  isDisabled: false,
})

// Accent color that comes in from the inline booking slice
const accentColor: { value: string } = inject('accentColor') as { value: string }

const locations: Ref<ResortInterface[]> = ref([])

const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})
const bookingExcludeResorts = computed(() => {
  return bookingWidgetStore.bookingExcludeResorts
})

function focus() {
  if (state.isDisabled)
    return

  state.isFocused = true
  if (typeof window !== 'undefined' && document !== null) {
    const bookingDestinationFormEl = document.getElementById('bookingDestinationForm')
    if (bookingDestinationFormEl) {
      const boundingRect = bookingDestinationFormEl.getBoundingClientRect()

      window.scrollTo({
        top: window.scrollY + boundingRect.top - 300,
        behavior: 'smooth',
      })
    }
  }
}

function loseFocus() {
  state.isFocused = false
}

function setDestination(location: ResortInterface) {
  const newBooking = Object.assign({}, props.modelValue)
  newBooking.destination = location
  newBooking.dateRange = setBookingDates(location)

  emit('update:modelValue', newBooking)
  emit('resetRooms')
  loseFocus()
}

function clearDestination() {
  emit('input', null)
  emit('resetDates')
  state.isFocused = true
}

function setBookingDates(location: BookingLocation) {
  let start = dayjs().toDate()
  let end = dayjs().add(1, 'days').toDate()
  if (location.booking_settings.min_booking_date != null) {
    start = dayjs(
      location.booking_settings.min_booking_date,
    ).toDate()
    end = dayjs(location.booking_settings.min_booking_date)
      .add(1, 'days')
      .toDate()
  }

  return { start, end }
}

// TODO Utilize this when we add agentcashplus
// eslint-disable-next-line unused-imports/no-unused-vars
const showAgentFields = computed(() => {
  return currentPage.value?.site_id === 9
})

async function retrieveResorts() {
  let params = ''

  if (currentPage.value) {
    const currentResortAndBrand = {
      resort: currentResort,
    }

    if (!currentResort) {
      // trying to use the currentResort value from the layout but
      // if it's not defined let's fetch it from the API
      await $voixFetch(`/api/sites/${currentPage.value?.site_id}/resorts${currentPage.value.path}`)
        .then((response) => {
          currentResortAndBrand.resort = response.data
        })
    }

    if (
      Object.hasOwnProperty.call(currentResortAndBrand.resort, 'booking_settings')
      && currentResortAndBrand.resort.booking_settings.only_same_site_resorts
    ) {
      params = `?site_id=${currentResortAndBrand.resort.site_id}`
    }

    if (currentResortInStaging) {
      if (params.includes('?')) {
        params += '&'
      }
      else {
        params += '?'
      }
      params += `force_resort_id=${currentResortAndBrand.resort.id}`
    }

    await $voixFetch(`/api/resorts/by-location${params}`).then((response) => {
      locations.value = response
    })

    if (
      bookingExcludeResorts.value
      && bookingExcludeResorts.value !== ''
    ) {
      Object.keys(locations.value).forEach((locationName) => {
        const theLocation = locations.value[locationName]

        locations.value[locationName] = theLocation.filter((resort) => {
          return !bookingExcludeResorts.value.includes(
            resort.name,
          )
        })

        if (locations.value[locationName] < 1)
          delete locations.value[locationName]
      })
    }

    let totalResorts = 0
    for (const local in locations.value)
      totalResorts += locations.value[local].length

    if (totalResorts <= 1) {
      for (const local in locations.value)
        setDestination(locations.value[local][0])
      state.isDisabled = true
    }
    else if (currentResortAndBrand?.resort?.id && currentResortAndBrand?.resort?.show_on_booking_widget) {
      setDestination(currentResortAndBrand.resort)
    }
  }
}

await retrieveResorts()
</script>

<template>
  <div class="relative">
    <div class="relative">
      <input
        id="bookingDestinationForm"
        v-model="state.search"
        type="text"
        readonly
        class="px-0 py-1 text-sm w-full lg:w-[180px] border-0 border-b-2 border-gray-300 tracking-wide focus:border-playa-action focus:outline-none focus:ring-0"
        @click="focus"
      >

      <div v-if="!props.modelValue.destination" class="absolute top-0 left-0 cursor-pointer flex space-x-1 text-xs bg-gray-200 px-2 py-1 rounded text-gray-400" @click="focus">
        <Icon name="heroicons:map" class="w-4 h-4" />
        <span>{{ $t('select-destination-first') }}</span>
      </div>

      <div
        v-if="!state.isFocused"
        class="absolute pointer-events-none z-10 top-0 bottom-0 right-0 left-0 flex items-center"
      >
        <div
          v-if="!props.modelValue"
          class="uppercase text-gray-300 text-sm"
        >
          Select Destination
        </div>
        <div
          v-else
          class="w-full hover:bg-gray-200 py-1 rounded flex items-center space-x-4 text-left"
          @click="clearDestination"
        >
          <div>
            <div
              class="text-sm font-medium text-playa-dark-blue leading-0 line-clamp-1"
            >
              <ClientOnly>
                {{ props.modelValue.destination?.name }}
              </ClientOnly>
            </div>
          </div>
        </div>
      </div>
      </input>
      <div
        v-if="state.isFocused"
        v-on-click-outside="loseFocus"
        class="lg:absolute lg:z-50 lg:top-0 lg:left-0 lg:translate-y-12 bg-white rounded mt-3 lg:mt-0 lg:p-8 lg:w-[800px] max-h-[50vh] overflow-scroll lg:max-h-[300px] lg:overflow-y-scroll grid lg:grid-cols-3 gap-x-12 gap-y-6"
      >
        <div
          v-for="(resorts, location) in locations"
          :key="location"
        >
          <div
            class=" text-white p-1 rounded text-xs font-bold"
            :style="{ backgroundColor: accentColor.value }"
          >
            {{ location }}
          </div>
          <ul class="flex flex-col space-y-2 -mx-4 mt-2">
            <li v-for="resort in resorts" :key="resort.id">
              <button
                class="w-full hover:bg-gray-200 px-4 py-1 rounded flex items-center space-x-4 text-left"
                @click="setDestination(resort)"
              >
                <div>
                  <div
                    class="text-sm font-medium text-playa-dark-blue leading-0"
                  >
                    {{ resort.name }}
                  </div>
                  <div

                    v-if="
                      resort.booking_settings.adults_only
                    "
                    class="text-xs font-medium leading-0"
                    :style="{ color: accentColor.value }"
                  >
                    Adults Exclusive Resort (Age 18+)
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
