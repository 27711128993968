<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import type { AirportInterface } from '@playa/types'
import { useAirportsStore } from './store/airportStore'

const emit = defineEmits(['input'])

// Accent color that comes in from the inline booking slice
const accentColor: { value: string } = inject('accentColor') as { value: string }

const state = reactive({
  search: '',
  isFocused: false,
  selectedAirport: null,
} as {
  search: string
  isFocused: boolean
  selectedAirport: AirportInterface | null
})

const airportStore = useAirportsStore()
async function getAirports() {
  const { data: airports } = await $voixNuxtApi(
    `/api/admin/playa/airports`,
  )
  airportStore.setAirports(airports.value)
}

// Setup the store and fetch the airports
const airportsStore = useAirportsStore()
getAirports()

const filteredAirports: ComputedRef<AirportInterface[]> = computed(() => {
  if (state.search.length < 3)
    return []

  return airportsStore.airports.filter((airport: AirportInterface) => {
    if (
      airport.name
        .toLowerCase()
        .includes(state.search.toLowerCase())
    )
      return true
    return airport.code
      .toLowerCase()
      .includes(state.search.toLowerCase())
  })
})

function focus() {
  state.isFocused = true
  if (state.selectedAirport) {
    state.search = state.selectedAirport.code
    state.selectedAirport = null
  }
}

function loseFocus() {
  state.isFocused = false
}

function setFrom(airport: AirportInterface) {
  state.selectedAirport = airport
  state.isFocused = false
  state.search = ''
  emit('input', airport.code)
}

const showDropdown = computed(() => {
  return filteredAirports.value.length > 0
})

watch(showDropdown, (value) => {
  if (value && document !== null) {
    const bookingAirportFormEl = document.getElementById('bookingAirportForm')
    if (!bookingAirportFormEl)
      return

    const boundingRect = bookingAirportFormEl.getBoundingClientRect()

    window.scrollTo({
      top: window.scrollY + boundingRect.top - 300,
      behavior: 'smooth',
    })
  }
})
</script>

<template>
  <div id="bookingAirportForm" class="relative">
    <div class="relative">
      <input
        v-model="state.search"
        type="text"
        class="px-0 py-1 border-0 border-b-2 w-full border-gray-300 placeholder:uppercase placeholder:text-gray-300 tracking-wide text-sm focus:border-playa-action focus:outline-none focus:ring-0"
        @click="focus"
      >
      <div
        v-if="state.selectedAirport && !state.search"
        class="absolute pointer-events-none z-10 top-0 bottom-0 right-0 left-0 flex items-center"
      >
        <div
          v-if="!state.selectedAirport"
          class="uppercase text-gray-300 text-sm"
        >
          Search Airports
        </div>
        <div
          v-else
          class="w-full hover:bg-gray-200 py-1 rounded flex items-center space-x-4 text-left"
        >
          <div class="flex items-center space-x-2">
            <div
              class="leading-0 line-clamp-1 text-white p-1 rounded text-xs font-bold"
              :style="{ backgroundColor: accentColor.value }"
            >
              {{ state.selectedAirport.code }}
            </div>
            <div
              class="flex-1 text-sm font-medium text-playa-dark-blue leading-0 line-clamp-1"
            >
              {{ state.selectedAirport.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showDropdown">
      <ul
        v-if="state.isFocused"
        v-on-click-outside="loseFocus"
        class="lg:absolute top-0 left-0 lg:translate-y-12 lg:-translate-x-12 bg-white rounded lg:p-6 lg:min-w-[350px]"
      >
        <li v-for="airport in filteredAirports" :key="airport.code">
          <button
            class="w-full hover:bg-gray-200 px-4 py-1 rounded flex items-center space-x-4 text-left"
            @click="setFrom(airport)"
          >
            <div
              class="text-white p-1 rounded text-xs font-bold"
              :style="{ backgroundColor: accentColor.value }"
            >
              {{ airport.code }}
            </div>
            <div>
              <div
                class="text-sm font-medium text-playa-dark-blue leading-0"
              >
                {{ airport.name }}
              </div>
            </div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
